
























































































import Vue from 'vue';

import Field from '@/components/Field.vue';
import SelectField from '@/components/SelectField.vue';
import DatePicker from '@/components/DatePicker.vue';
import RCP from '@/api/RCP';

export default Vue.extend({
    name: 'Events',
    data() {
      const today = new Date();
      let year = today.getFullYear();
      const monthCalc = today.getMonth() + 1;
      let month = ('0' + (monthCalc)).slice(-2);
      const day = '01';
      let secondYear = year;
      let secondMonth = ('0' + (monthCalc)).slice(-2);
      const secondDay = new Date(year, monthCalc, 0).getDate()

        if (month === '12') {
            secondYear += 1;
            secondMonth = '01';
        }

        let first = '';
        let second = '';

        first += `${year}-${month}-${day}`;
        second += `${secondYear}-${secondMonth}-${secondDay}`;

        return {
            params: {
                from: first,
                to: second,
                status: 'new',
                project_id: 0,
                partner: 'all',
                events: [] as any
            },
            options: {
                status: {
                    new: 'Первичники',
                    old: 'Повторники'
                }
            },
            projects: [],
            partners: [] as any,
            events: [{
                code: 'registration',
                title: 'Регистрация'
            }, {
                code: 'decline',
                title: 'Отказ'
            }, {
                code: 'approve',
                title: 'Одобрено'
            }, {
                code: 'issue',
                title: 'Выдача'
            }],
            projectsMap: new Map(),
            datesError: false,
            request: false,
            progress: 0
        };
    },
    computed: {
        downloadLink(): string {
            let partners = this.params.partner as any;

            if (partners === 'all' && this.partners[0]) {
                partners = +this.partners[0].id;

                for (let i = 1; i < this.partners.length; i++) {
                    partners +=  ',' + this.partners[i].id;
                }
            }

            return `/report?params={"reportName":"Events","from":"${this.params.from}","to":"${this.params.to}","user_status":"${this.params.status}","partners":[${partners}]}`;
        }
    },
    mounted() {
        this.getFilters();
    },
    watch: {
        'projects'(val: any) {
            if (val.length === 0) {
                return;
            }

            this.params.project_id = val[0].id;

            this.projectsMap = new Map(val.map((element: any) => {
                return [element.id, element.partners];
            }));
        },
        'params.project_id'(val: any) {
            this.params.partner = 'all';
            this.partners = this.projectsMap.get(val);
        },
        'params': {
            handler() {
                this.checkDates();
            },
            deep: true
        }
    },
    methods: {
        getReportHash() {
            if (this.request) {
                return;
            }

            this.progress = 0;
            this.request = true;

            let partners = [] as any;

            if (this.params.partner === 'all') {
                partners = this.partners.map(({id}) => {
                    return +id;
                });
            } else {
                partners.push(this.params.partner);
            }

            const params = {
                reportName: 'events',
                from: this.params.from,
                to: this.params.to,
                partners,
                userStatus: this.params.status
            } as any;

            if (this.params.events.length > 0) {
                params.actions = this.params.events.map((value) => value.code);
            }

            RCP({
                method: 'report',
                id: 'getReportHash',
                params
            }, 'report').then(({ hash = '' }) => {
                if (hash) {
                    this.getReportByHash(hash);
                } else {
                    throw new Error('undefined error');
                }
            }).catch((e) => {
                console.warn(e);
            }).finally(() => {
                this.request = false;
            });
        },
        getReportByHash(hash: string) {
            this.request = true;

            RCP({
                method: 'getReportLink',
                id: 'getReportByHash',
                params: {
                    hash
                }
            }, 'report').then(({ percent, link: url = '' }) => {
                if (percent === undefined) {
                    throw new Error('undefined error');
                } else if (percent < 100) {
                    this.getReportByHash(hash);
                } else {
                    const link = document.createElement('a');

                    link.setAttribute('href', url);
                    link.setAttribute('target', '_blank');
                    // link.setAttribute('download', 'download');

                    link.click();
                    link.remove();
                }

                this.progress = percent;
            }).catch((e) => {
                console.warn(e);
                this.request = false;
            });
        },
        checkDates() {
            if (this.params.from > this.params.to) {
                this.datesError = true;
            } else {
                this.datesError = false;
            }
        },
        getFilters() {
            return new Promise((resolve) => {
                this.projects = this.$store.state.filters.projects;
                resolve();
            });
        }
    },
    components: {
        Field,
        SelectField,
        DatePicker,
        MultiSelect: () => import('vue-multiselect')
    }
});
